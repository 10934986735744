import SwiperSlider from "./module/SwiperSlider";

var sw = window.innerWidth;
var sh = window.innerHeight;
var header = document.querySelector("header");
var sticky = 0;

// Font Resizer ------------------- Start
function fontResizer() {
  var perc = parseInt(sw) / 120;
  document.body.style.fontSize = perc + 'px';
}


// Loader ------------------- Start
function pageLoader() {
  // document.querySelector("html").classList.add("loadjs");
  setTimeout(function () {
    if (document.querySelector(".loader-first")) {
      document.querySelector(".loader-first").style.display = "none";
      document.querySelector("html").classList.remove("loadjs");
    }
  }, 1000);
}

// Window on Load ----------------- Start
window.onload = function () {
  pageLoader();
  if (sw > 1199) {
    fontResizer();
  }
};

// Window On Resize ----------------- Start
(function () {
  window.addEventListener('resize', (event) => {
    sw = window.innerWidth;
    sh = window.innerHeight;
    if (sw > 1025) {
      if (sw < 1400 && sw > 1300 && sh > 900) { } else {
        fontResizer();
      }
    }
  });
})();




// Landscape Mode off ---------------- Start
const mql = window.matchMedia("(orientation:landscape)");
mql.addEventListener("change", (event) => {
  if (event.matches) {
    if (innerWidth < 930) {
      // console.log("Now in landscape orientation");
      document.getElementById("portrait-warning").style.display = "flex";
    }
  } else {
    // console.log("Now in portrait orientation");
    document.getElementById("portrait-warning").style.display = "none";
  }
});


// Sticky Header ---------------- Start
(function () {
  if (document.querySelector('.header')) {
    function stickyHeader() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
    window.addEventListener('scroll', (event) => {
      stickyHeader();
    });
  }
})();





// SVG Convert Code
window.addEventListener("load", function () {
  (function () {
    if (document.querySelector(".svg-convert")) {
      let svgs = document.querySelectorAll('img.svg-convert');
      svgs.forEach((el) => {
        const imgID = el.getAttribute('id');
        const imgClass = el.getAttribute('class');
        const imgURL = el.getAttribute('src');

        fetch(imgURL)
          .then((response) => response.text())
          .then(data => {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(data, 'text/html');
            let svg = xmlDoc.querySelector('svg');
            if (typeof imgID !== 'undefined') {
              svg.setAttribute('id', imgID);
            }

            if (typeof imgClass !== 'undefined') {
              svg.setAttribute('class', imgClass + ' svg-converted');
            }

            svg.removeAttribute('xmlns:a');

            el.parentNode.replaceChild(svg, el);
          })
      });
    }
  })();

});





// animate Observer------------Start
(function () {
  if (window.innerWidth > 767) {
    let anim = [...document.querySelectorAll(".animate")];

    let options = {
      rootMargin: "0px",
      threshold: 0.2,
    };

    let animate = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.animation = `anim 1s ${entry.target.dataset.delay} forwards ease-out`;
        }
      });
    };

    let observer = new IntersectionObserver(animate, options);

    anim.forEach((item, idx) => {
      observer.observe(item);
    });
  }
})();


// Lazy Load Img observer
(function () {
  document.addEventListener("DOMContentLoaded", () => {
    const imgElements = document.querySelectorAll("img[data-src]");

    const lazyLoadingImage = (entries, observer2) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;
        entry.target.src = entry.target.dataset.src;
        entry.target.addEventListener("load", () => {
          entry.target.classList.remove("lazy-img");
          observer2.unobserve(entry.target);
        });
      });
    };
    const lazyLoadingObserver = new IntersectionObserver(lazyLoadingImage, {
      threshold: 0,
      rootMargin: "0px 0px 150px 0px",
    });
    imgElements.forEach((img) => lazyLoadingObserver.observe(img));
  });
})();



// clip path observer
(function () {

  if (window.innerWidth > 767) {

    let imageObserver = [...document.querySelectorAll(".img__observer")];
    let option = {
      rootMargin: "-200px",
      threshold: 0.2,
    };

    let setItemActive = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
        }
      });
    };

    let observer2 = new IntersectionObserver(setItemActive, option);

    imageObserver.forEach((item1, idx) => {
      observer2.observe(item1);
    });


  }

})();












// -------------------- above code will be common for all websites -------------------------- //


// Generic Slider Module Create slider which contains the class swiper.
const swiperSliders = document.querySelectorAll(".swiper");
swiperSliders.forEach((item) => new SwiperSlider(item));


// if (document.querySelector(".home-hero__heroSlider")) {
//   var heroSlider = new Swiper(".home-hero__heroSlider", {
//     slidesPerView: 1,
//     allowTouchMove: true,
//     loop: true,
//     watchSlidesProgress: true,
//     speed: 1000,
//     pagination: {
//       el: '.home-hero__heroSlider .swiper-pagination',
//       clickable: true,
//       // dynamicBullets: true,
//     },
//     autoplay: {
//       delay: 10000,
//       disableOnInteraction: false,
//     },
//     on: {
//       init: function (e) {
//         const slide = e.slides[0];
//         addIFrame(e.slides, 0);

//       },
//       slideChange: function ({
//         realIndex,
//         slides
//       }) {
//         addIFrame(slides, realIndex);
//       }
//     }
//   });
// }

// Function for loading iframe video on slider to optimize page load
function addIFrame(slide, index) {
  slide.forEach(item => {
    let x = item.children[0].getElementsByClassName('iframeVidz');
    if (x.length) {
      x[0].remove()
    }
  })
  let dsrc1 = document.querySelector(".iframeData").getAttribute("data-src");
  let iFrame1 =
    '<iframe class="iframeVidz" src="' +
    dsrc1 +
    '" style=""></iframe>';
  setTimeout(function () {
    let a = slide[index].querySelector(".iframeData")
    if (a) {
      a.innerHTML = iFrame1
    }
  }, 500);
}


// var swiper = new Swiper(".serviceSlider", {
//   slidesPerView: 1,
//   spaceBetween: 40,
//   breakpoints: {
//     768: {
//       slidesPerView: 1.4,
//     },
//     1024: {
//       slidesPerView: "auto",
//     },
//   },
//   scrollbar: {
//     el: ".swiper-scrollbar",
//   },
//   navigation: {
//     nextEl: ".swiper-button-next",
//     prevEl: ".swiper-button-prev",
//   },
// });





// Sticky Header ---------------- Start
(function () {
  if (document.querySelector(".header")) {
    // Sticky Header Class Add and Remove ---------------- Start
    function stickyHeader() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
    // Sticky Header ---------------- End

    // Window On Scroll-----------------Start
    window.addEventListener("scroll", (event) => {
      stickyHeader();
    });
  }
})();
// Sticky Header ---------------- End


// page active link
(function () {
  window.addEventListener("load", function (e) {
    let myPage = location.pathname,
      link = document.querySelector(
        `.menu__menuitem--menulink[href="${myPage}"]`
      ),
      innerDropdownLink = document.querySelector(
        `.dropdown__item a[href="${myPage}"]`
      );

    if (link) {
      link.classList.add("active");
    }
    if (innerDropdownLink) {
      let linkParent = innerDropdownLink.closest(".menu__menuitem");
      let currentLink = linkParent.querySelector(".menu__menuitem--menulink");
      currentLink.classList.add("active");
      //  Current Link
      let currentLinkDropdown = innerDropdownLink.closest(
        ".dropdown__item > a"
      );
      currentLinkDropdown.classList.add("active");
    }
  });
})();




// Navigation slide Active on a mobile ---------------- Start
(function () {
  if (document.querySelector("#toggle")) {
    let toggleMenu = document.querySelector("#toggle");
    let htmlEl = document.querySelector("html");

    toggleMenu.addEventListener("click", (event) => {
      let _this = event.target;
      _this.classList.toggle("active");
      document.querySelector(".navigation-wrapper--main").classList.toggle("active");
      document.querySelector(".navigation--main").classList.toggle("open");
      htmlEl.classList.toggle("overflow-hidden");
    });
  }
})();
// Navigation slide Active on a mobile  ---------------- End

// Navigation  inner slide Active on a mobile ---------------- Start
(function () {
  if (document.querySelector("#toggleInner")) {
    let toggleMenuInner = document.querySelector("#toggleInner");
    let htmlEl = document.querySelector("html");

    toggleMenuInner.addEventListener("click", (event) => {
      let _this = event.target;
      _this.classList.toggle("active");
      document.querySelector(".navigation--main").classList.toggle("open");
      htmlEl.classList.toggle("overflow-hidden");
    });
  }
})();
// Navigation slide Active on a mobile  ---------------- End

// main navigation hover
(function () {
  if (sw > 1199) {
    let mainItems = document.querySelectorAll(".navigation--main .menu__menuitem--menulink"),
      navigation__cards = document.querySelectorAll(".navigation--main__content .navigation--main__content__card");

    mainItems.forEach(function (item) {
      item.addEventListener("mouseover", function (evt) {
        evt.stopPropagation();

        mainItems.forEach(function (itm) {
          itm.classList.remove("active");
        });
        evt.target.classList.add("active");

        let currentCardId = evt.target.dataset.id;
        let currentCard = document.querySelector(`#${currentCardId}`);

        navigation__cards.forEach(function (card) {
          card.classList.remove("active");
        });

        currentCard.classList.add("active");
      });
    });
  }
})();


if (document.querySelector(".footer")) {

  let toggleFooter = document.querySelectorAll(".toggle-btn");


  if (sw < 767) {
    if (toggleFooter.length > 0) {
      for (let i = 0; i < toggleFooter.length; i++) {
        const setActive = (el) => {
          let bol = el.classList.contains('opened');
          [...el.parentElement.parentElement.children].forEach((sib) => {
            sib.children[0].classList.remove("opened");
            let panel = sib.children[1];
            panel.style.maxHeight = null;
            panel.classList.remove("opened");
          });
          if (bol == false) {
            el.classList.add("opened");
            let panel = el.nextElementSibling;
            panel.classList.add("opened")
            panel.style.maxHeight = panel.scrollHeight + "px";
          }
        };
        let spans = [...toggleFooter[i].querySelectorAll(".toggle-btn > h5")];
        spans.forEach((el) => el.addEventListener("click", (e) => setActive(el)));
      }
    }
  }


  let wrapper = document.querySelector(".fLinkWrapper");

  toggleFooter[3].childNodes[3].style.maxHeight = toggleFooter[3].childNodes[3].scrollHeight + "px";
}


// navigation accordion
(function () {
  if (sw < 1025) {
    let dropdown = document.querySelectorAll(".innerMenu .navigation--main__content");
    dropdown.forEach(function (dp) {
      let dropdownItem = dp.closest(".menu__menuitem");
      dropdownItem.classList.add("dropdown-active");
    });

    let accordionNav = document.querySelectorAll(".navigation-wrapper--main .menu__menuitem");
    let iterator;


    for (iterator = 0; iterator < accordionNav.length; iterator++) {
      if (accordionNav[iterator].querySelector(".menu__menuitem__icon")) {
        accordionNav[iterator].querySelector(".menu__menuitem__icon").addEventListener("click", function (e) {
          e.stopPropagation();
          let currentItem = e.target;

          // console.log(currentItem)

          let panel = this.nextElementSibling;
          if (currentItem.classList.contains('open')) {
            currentItem.classList.remove("open");
            panel.style.maxHeight = '0px';
          } else {
            let mainItem = currentItem.closest(".menu").querySelectorAll(".menu__menuitem__icon");
            mainItem.forEach(function (itm) {
              itm.classList.remove("open");
              if (itm.nextElementSibling) {
                itm.nextElementSibling.style.maxHeight = '0px';
              }
            });
            currentItem.classList.add("open");
            panel.style.maxHeight = panel.scrollHeight + 'px';
          }
        });

      }
    }
  }
})();

(function () {
  if (document.querySelector(".slidertabSec")) {

    const filters = document.querySelectorAll('.tabs'),
      filterWrapper = document.querySelector('.tabWrapper'),
      fistFilter = filterWrapper.querySelector('.tabs');

    fistFilter.classList.add("active")


    filters.forEach(filter => {


      filter.addEventListener('click', function () {



        let selectedFilter = filter.getAttribute('data-filter');
        let itemsToHide = document.querySelectorAll(`.serviceSliderWrapper .serviceSlider:not([data-filter='${selectedFilter}'])`);
        let itemsToShow = document.querySelectorAll(`.serviceSliderWrapper .serviceSlider[data-filter='${selectedFilter}']`);

        filters.forEach(el => {
          el.classList.remove('active');
        });
        this.classList.add('active')

        if (selectedFilter == 'all') {
          itemsToHide = [];
          itemsToShow = document.querySelectorAll('.serviceSlider[data-filter]');
        }

        itemsToHide.forEach(el => {
          el.classList.add('hide');
          el.classList.remove('show');

        });
        itemsToShow.forEach(el => {
          el.classList.remove('hide');
          el.classList.add('show');
          console.log("show");
        });
      });
    });
  }
})();


